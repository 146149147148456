.mysubtitle {
  text-transform: uppercase;
  background: linear-gradient(
    147deg,
    rgba(249, 15, 216, 1) 0%,
    rgba(245, 67, 119, 1) 26%,
    rgba(252, 28, 28, 1) 50%,
    rgb(146, 111, 5) 75%,
    rgb(26, 126, 9) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font: 18px Arial;
  font-weight: bold;
}
