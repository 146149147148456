.mytextzone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 840px;
  height: 120px;
  background: linear-gradient(
    147deg,
    rgba(255, 237, 216, 1) 0%,
    rgb(245, 201, 214) 26%,
    rgb(241, 222, 241) 50%,
    rgb(240, 229, 196) 75%,
    rgb(182, 238, 199) 100%
  );
}

.mytext {
  font: 30px Arial;
  font-weight: bold;
  letter-spacing: 5px;
}

.current {
  font: 30px Arial;
  font-weight: bold;
  background-color: white;
  display: block;
}

/* Текст */
.mytitle {
  text-transform: uppercase;
  background-color: #001529;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font: 40px Arial;
  font-weight: bold;
}
.mysubtitle {
  text-transform: uppercase;
  background: linear-gradient(
    147deg,
    rgba(249, 15, 216, 1) 0%,
    rgba(245, 67, 119, 1) 26%,
    rgba(252, 28, 28, 1) 50%,
    rgb(146, 111, 5) 75%,
    rgb(26, 126, 9) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font: 18px Arial;
  font-weight: bold;
}
