.hand {
  height: 55px;
  position: relative;

  display: block;
  margin-left: 80%;
  margin-top: 0%;
}

.finger {
  background: #eff0f2;
  border-radius: 2px 2px 2px 2px;
  position: absolute;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;
}

.fingernew {
  background: #eff0f2;
  border-radius: 2px 2px 2px 2px;
  position: absolute;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;
}

.finger1 {
  border-radius: 2px 2px 2px 2px;
  position: absolute;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;
  background-color: orange;
  width: 10px;
  height: 35px;
  bottom: 0;
  left: 0;
  animation: shake 0.7s ease infinite;
  animation-delay: 0s;
}
.fingerstop1 {
  background: #eff0f2;
  border-radius: 2px 2px 2px 2px;
  position: absolute;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;
  background-color: #eff0f2;
  width: 10px;
  height: 35px;
  bottom: 0;
  left: 0;
}

.finger2 {
  background: #eff0f2;
  border-radius: 2px 2px 2px 2px;
  position: absolute;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;
  background-color: yellow;
  width: 10px;
  height: 40px;
  bottom: 0;
  left: 11px;
  animation: shake 0.7s ease infinite;
  animation-delay: 0.1s;
}

.finger3 {
  background: #eff0f2;
  border-radius: 2px 2px 2px 2px;
  position: absolute;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;
  background-color: blueviolet;
  width: 10px;
  height: 45px;
  bottom: 0px;
  left: 22px;
  animation: shake 0.7s ease infinite;
  animation-delay: 0.2s;
}

.finger4 {
  background: #eff0f2;
  border-radius: 2px 2px 2px 2px;
  position: absolute;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;
  background-color: #66ffff;
  width: 10px;
  height: 38px;
  bottom: 0;
  left: 33px;
  animation: shake 0.7s ease infinite;
  animation-delay: 0.3s;
}

.finger5 {
  background: #eff0f2;
  border-radius: 2px 2px 2px 2px;
  position: absolute;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;
  background-color: #b3ff66;
  width: 10px;
  height: 38px;
  bottom: 0;
  left: 82px;
  animation: shake 0.7s ease infinite;
  animation-delay: 0.3s;
}

.finger6 {
  background: #eff0f2;
  border-radius: 2px 2px 2px 2px;
  position: absolute;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;
  background-color: blueviolet;
  width: 10px;
  height: 45px;
  bottom: 0;
  left: 93px;
  animation: shake 0.7s ease infinite;
  animation-delay: 0.3s;
}
.finger7 {
  background: #eff0f2;
  border-radius: 2px 2px 2px 2px;
  position: absolute;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;
  background-color: yellow;
  width: 10px;
  height: 40px;
  bottom: 0;
  left: 104px;
  animation: shake 0.7s ease infinite;
  animation-delay: 0.3s;
}
.finger8 {
  border-radius: 2px 2px 2px 2px;
  position: absolute;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;
  background-color: orange;
  width: 10px;
  height: 35px;
  bottom: 0;
  left: 116px;
  animation: shake 0.7s ease infinite;
  animation-delay: 0.3s;
}

.thumb9 {
  position: absolute;
  background: #ff8a65;
  bottom: 0px;
  left: 44px;
  width: 13px;
  height: 16px;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;

  /* margin-top: 70px; */
  border-radius: 0px 15px 0px 0px;
  animation: shake 0.7s ease infinite;
  animation-delay: 0.4s;
}

.thumb10 {
  position: absolute;
  background: #ff8a65;
  bottom: 0px;
  left: 68px;
  width: 13px;
  height: 16px;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;

  /* margin-top: 70px; */
  border-radius: 15px 0px 0px 0px;
  animation: shake 0.7s ease infinite;
  animation-delay: 0.4s;
}

.finger:after {
  content: "";
  width: 8px;
  height: 5px;
  background: #aaa;
  bottom: 1px;
  left: 1px;
  position: absolute;
}
.finger:before {
  content: "";
  width: 8px;
  height: 10px;
  background: #aaa;
  top: 1px;
  left: 1px;
  position: absolute;
  border-radius: 30px 30px 20px 20px;
}

.hand .finger:nth-child(1) {
  background-color: #eff0f2;
  width: 10px;
  height: 35px;
  bottom: 0;
  left: 0;
  animation: shake 0.7s ease infinite;
  animation-delay: 0s;
}
.hand .finger:nth-child(2) {
  background-color: #eff0f2;
  width: 10px;
  height: 40px;
  bottom: 0;
  left: 11px;
  animation: shake 0.7s ease infinite;
  animation-delay: 0.1s;
}

.hand .finger:nth-child(3) {
  background-color: #eff0f2;
  width: 10px;
  height: 45px;
  bottom: 0px;
  left: 22px;
  animation: shake 0.7s ease infinite;
  animation-delay: 0.2s;
}

.hand .finger:nth-child(4) {
  background-color: #eff0f2;
  width: 10px;
  height: 38px;
  bottom: 0;
  left: 33px;
  animation: shake 0.7s ease infinite;
  animation-delay: 0.3s;
}

.hand .finger:nth-child(5) {
  background-color: #eff0f2;
  width: 10px;
  height: 38px;
  bottom: 0;
  left: 82px;
  animation: shake 0.7s ease infinite;
  animation-delay: 0.3s;
}

.hand .finger:nth-child(6) {
  background-color: #eff0f2;
  width: 10px;
  height: 45px;
  bottom: 0;
  left: 93px;
  animation: shake 0.7s ease infinite;
  animation-delay: 0.3s;
}

.hand .finger:nth-child(7) {
  background-color: #eff0f2;
  width: 10px;
  height: 40px;
  bottom: 0;
  left: 104px;
  animation: shake 0.7s ease infinite;
  animation-delay: 0.3s;
}

.hand .finger:nth-child(8) {
  background-color: #eff0f2;
  width: 10px;
  height: 35px;
  bottom: 0;
  left: 116px;
  animation: shake 0.7s ease infinite;
  animation-delay: 0.3s;
}

.thumb {
  position: absolute;
  background: #aaa;
  bottom: 0px;
  left: 44px;
  width: 15px;
  height: 20px;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;

  /* margin-top: 70px; */
  border-radius: 0px 15px 0px 0px;
  animation: shake 0.7s ease infinite;
  animation-delay: 0.4s;
}

.thumb2 {
  position: absolute;
  background: #aaa;
  bottom: 0px;
  left: 65px;
  width: 15px;
  height: 20px;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;

  /* margin-top: 70px; */
  border-radius: 15px 0px 0px 0px;
  animation: shake 0.7s ease infinite;
  animation-delay: 0.4s;
}

@keyframes shake {
  0% {
    bottom: 0;
  }

  50% {
    bottom: 10%;
  }

  100% {
    bottom: 0px;
  }
}

p {
  text-align: Center;
}

a {
  color: #fff;
}

.finger1:after {
  content: "";
  width: 8px;
  height: 5px;
  background: #aaa;
  bottom: 1px;
  left: 1px;
  position: absolute;
}
.finger2:after {
  content: "";
  width: 8px;
  height: 5px;
  background: #aaa;
  bottom: 1px;
  left: 1px;
  position: absolute;
}
.finger3:after {
  content: "";
  width: 8px;
  height: 5px;
  background: #aaa;
  bottom: 1px;
  left: 1px;
  position: absolute;
}
.finger4:after {
  content: "";
  width: 8px;
  height: 5px;
  background: #aaa;
  bottom: 1px;
  left: 1px;
  position: absolute;
}
.finger5:after {
  content: "";
  width: 8px;
  height: 5px;
  background: #aaa;
  bottom: 1px;
  left: 1px;
  position: absolute;
}
.finger6:after {
  content: "";
  width: 8px;
  height: 5px;
  background: #aaa;
  bottom: 1px;
  left: 1px;
  position: absolute;
}
.finger7:after {
  content: "";
  width: 8px;
  height: 5px;
  background: #aaa;
  bottom: 1px;
  left: 1px;
  position: absolute;
}
.finger8:after {
  content: "";
  width: 8px;
  height: 5px;
  background: #aaa;
  bottom: 1px;
  left: 1px;
  position: absolute;
}
.finger1:before {
  content: "";
  width: 8px;
  height: 10px;
  background: #aaa;
  top: 1px;
  left: 1px;
  position: absolute;
  border-radius: 30px 30px 20px 20px;
}

.finger2:before {
  content: "";
  width: 8px;
  height: 10px;
  background: #aaa;
  top: 1px;
  left: 1px;
  position: absolute;
  border-radius: 30px 30px 20px 20px;
}

.finger3:before {
  content: "";
  width: 8px;
  height: 10px;
  background: #aaa;
  top: 1px;
  left: 1px;
  position: absolute;
  border-radius: 30px 30px 20px 20px;
}

.finger4:before {
  content: "";
  width: 8px;
  height: 10px;
  background: #aaa;
  top: 1px;
  left: 1px;
  position: absolute;
  border-radius: 30px 30px 20px 20px;
}

.finger5:before {
  content: "";
  width: 8px;
  height: 10px;
  background: #aaa;
  top: 1px;
  left: 1px;
  position: absolute;
  border-radius: 30px 30px 20px 20px;
}

.finger6:before {
  content: "";
  width: 8px;
  height: 10px;
  background: #aaa;
  top: 1px;
  left: 1px;
  position: absolute;
  border-radius: 30px 30px 20px 20px;
}

.finger7:before {
  content: "";
  width: 8px;
  height: 10px;
  background: #aaa;
  top: 1px;
  left: 1px;
  position: absolute;
  border-radius: 30px 30px 20px 20px;
}

.finger8:before {
  content: "";
  width: 8px;
  height: 10px;
  background: #aaa;
  top: 1px;
  left: 1px;
  position: absolute;
  border-radius: 30px 30px 20px 20px;
}

.fingerstop1:before {
  content: "";
  width: 8px;
  height: 10px;
  background: #aaa;
  top: 1px;
  left: 1px;
  position: absolute;
  border-radius: 30px 30px 20px 20px;
}
.fingerstop1:after {
  content: "";
  width: 8px;
  height: 5px;
  background: #aaa;
  bottom: 1px;
  left: 1px;
  position: absolute;
}

.fingerstop2 {
  background: #eff0f2;
  border-radius: 2px 2px 2px 2px;
  position: absolute;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;
  background-color: #eff0f2;
  width: 10px;
  height: 40px;
  bottom: 0;
  left: 11px;
}

.fingerstop3 {
  background: #eff0f2;
  border-radius: 2px 2px 2px 2px;
  position: absolute;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;
  background-color: #eff0f2;
  width: 10px;
  height: 45px;
  bottom: 0px;
  left: 22px;
}

.fingerstop4 {
  background: #eff0f2;
  border-radius: 2px 2px 2px 2px;
  position: absolute;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;
  background-color: #eff0f2;
  width: 10px;
  height: 38px;
  bottom: 0;
  left: 33px;
}

.fingerstop5 {
  background: #eff0f2;
  border-radius: 2px 2px 2px 2px;
  position: absolute;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;
  background-color: #eff0f2;
  width: 10px;
  height: 38px;
  bottom: 0;
  left: 82px;
}

.fingerstop6 {
  background: #eff0f2;
  border-radius: 2px 2px 2px 2px;
  position: absolute;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;
  background-color: #eff0f2;
  width: 10px;
  height: 45px;
  bottom: 0;
  left: 93px;
}
.fingerstop7 {
  background: #eff0f2;
  border-radius: 2px 2px 2px 2px;
  position: absolute;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;
  background-color: #eff0f2;
  width: 10px;
  height: 40px;
  bottom: 0;
  left: 104px;
}
.fingerstop8 {
  border-radius: 2px 2px 2px 2px;
  position: absolute;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;
  background-color: #eff0f2;
  width: 10px;
  height: 35px;
  bottom: 0;
  left: 116px;
}

.thumbstop9 {
  position: absolute;
  background-color: #eff0f2;
  bottom: 0px;
  left: 44px;
  width: 13px;
  height: 16px;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;

  /* margin-top: 70px; */
  border-radius: 0px 15px 0px 0px;
}

.thumbstop10 {
  position: absolute;
  background-color: #eff0f2;
  bottom: 0px;
  left: 68px;
  width: 13px;
  height: 16px;
  -webkit-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  -moz-box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  box-shadow: inset 0 0 2px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9,
    0 2px 3px #333;
  text-shadow: 0px 1px 0px #f5f5f5;

  border-radius: 15px 0px 0px 0px;
}

.fingerstop2:before {
  content: "";
  width: 8px;
  height: 10px;
  background: #aaa;
  top: 1px;
  left: 1px;
  position: absolute;
  border-radius: 30px 30px 20px 20px;
}
.fingerstop2:after {
  content: "";
  width: 8px;
  height: 5px;
  background: #aaa;
  bottom: 1px;
  left: 1px;
  position: absolute;
}

.fingerstop3:before {
  content: "";
  width: 8px;
  height: 10px;
  background: #aaa;
  top: 1px;
  left: 1px;
  position: absolute;
  border-radius: 30px 30px 20px 20px;
}
.fingerstop3:after {
  content: "";
  width: 8px;
  height: 5px;
  background: #aaa;
  bottom: 1px;
  left: 1px;
  position: absolute;
}

.fingerstop4:before {
  content: "";
  width: 8px;
  height: 10px;
  background: #aaa;
  top: 1px;
  left: 1px;
  position: absolute;
  border-radius: 30px 30px 20px 20px;
}
.fingerstop4:after {
  content: "";
  width: 8px;
  height: 5px;
  background: #aaa;
  bottom: 1px;
  left: 1px;
  position: absolute;
}

.fingerstop5:before {
  content: "";
  width: 8px;
  height: 10px;
  background: #aaa;
  top: 1px;
  left: 1px;
  position: absolute;
  border-radius: 30px 30px 20px 20px;
}
.fingerstop5:after {
  content: "";
  width: 8px;
  height: 5px;
  background: #aaa;
  bottom: 1px;
  left: 1px;
  position: absolute;
}

.fingerstop6:before {
  content: "";
  width: 8px;
  height: 10px;
  background: #aaa;
  top: 1px;
  left: 1px;
  position: absolute;
  border-radius: 30px 30px 20px 20px;
}
.fingerstop6:after {
  content: "";
  width: 8px;
  height: 5px;
  background: #aaa;
  bottom: 1px;
  left: 1px;
  position: absolute;
}
.fingerstop7:before {
  content: "";
  width: 8px;
  height: 10px;
  background: #aaa;
  top: 1px;
  left: 1px;
  position: absolute;
  border-radius: 30px 30px 20px 20px;
}
.fingerstop7:after {
  content: "";
  width: 8px;
  height: 5px;
  background: #aaa;
  bottom: 1px;
  left: 1px;
  position: absolute;
}

.fingerstop8:before {
  content: "";
  width: 8px;
  height: 10px;
  background: #aaa;
  top: 1px;
  left: 1px;
  position: absolute;
  border-radius: 30px 30px 20px 20px;
}
.fingerstop8:after {
  content: "";
  width: 8px;
  height: 5px;
  background: #aaa;
  bottom: 1px;
  left: 1px;
  position: absolute;
}
